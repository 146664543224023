@import "app/colors";

.ag-theme-balham {
  --ag-header-foreground-color: rgba(0, 0, 0, 0.54);
  --ag-odd-row-background-color: $white;

  --ag-font-size: 12px;
  --ag-font-family: "OrstedSans-Regular";
  --ag-row-hover-color: rgba(153, 164, 174, 0.3);
  --ag-row-border-width: 0;

  --ag-row-height: 40px;
  --ag-header-height: 40px;

  .ag-header {
    font-weight: bold;
    font-family: OrstedSans-Bold, sans-serif;
  }

  .ag-header-row {
    background-color: $aqua;
  }

  .ag-row-odd {
    background-color: rgba(216, 209, 202, 0.3);
  }

  .ag-row-even {
    background-color: $white;
  }

  .ag-row-selected {
    background-color: #8ecdc84d;
  }

  .ag-header-cell {
    &:has(div[ref="eResize"]:not(.ag-hidden))::after {
      background-color: #74b1ad;
    }
  }

  .ag-has-focus .ag-cell-focus,
  .ag-has-focus .ag-cell-focus,
  .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border: none;
    outline: none;
  }

  .ag-pinned-left-header {
    border-right: none;
  }

  .ag-cell {
    display: flex;
    align-items: center;
  }

  .ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned {
    border-right: none !important;
  }
}

.clickable-link {
  color: #007bff;
  cursor: pointer;
}

@use "@angular/material" as mat;
@import "app/colors";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat.define-palette(mat.$blue-palette, 600);
$candy-app-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $candy-app-primary,
      accent: $candy-app-accent,
      warn: $candy-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($candy-app-theme);

.mat-icon {
  color: white;
  position: relative;
  float: left;
  font-size: 20px;
}

[mat-toolbar].mat-toolbar {
  font-family: "OrstedSans-Regular";
  background-color: $blue;
  border-bottom: 0px solid rgb(240, 240, 240);

  &.mat-toolbar-single-row {
    padding: 0;
  }
}

.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-family: "OrstedSans-Regular";
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text a {
  color: black;
}

.mat-mdc-menu-panel.table-row-menu {
  margin: 5px;
}

.mat-mdc-slide-toggle label {
  font-family: "OrstedSans-Regular";
  font-size: 1rem;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: $white;
  --mdc-snackbar-supporting-text-font: "OrstedSans-Regular";
  --mdc-snackbar-supporting-text-size: 16px;
}

.mdc-tab {
  font-family: "OrstedSans-Regular", "Roboto", sans-serif;
  letter-spacing: normal;
}

.mat-mdc-slide-toggle .mdc-form-field {
  display: block;
}

.mat-mdc-slide-toggle .mdc-form-field button {
  min-width: 0px;
}

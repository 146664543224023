button,
input[type="button"] {
  border-radius: 41px;
  background-color: $blue;
  color: $white;
  padding: 6px 14px;
  border: 0px;
  outline: none;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin: 5px;
  min-width: 120px;
  &:focus {
    outline: none;
  }

  &:hover,
  :active {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    outline: 0;
  }
}

button.secondary-button {
  border-radius: 22px;
  border-color: $blue;
  border-width: 1.5px;
  border-style: solid;
  background-color: $white;
  color: $blue;
  padding: 6px 14px;
  outline: none;
  font-family: "OrstedSans-Regular";
  font-weight: normal;
  cursor: pointer;
  min-width: 120px;
}

button.link-button {
  border-radius: 0px;
  background-color: $white;
  color: $blue;
  padding: 6px 14px;
  outline: none;
  font-family: "OrstedSans-Regular";
  font-weight: normal;
  cursor: pointer;
  min-width: 120px;
  transition: none;
  box-shadow: none;
}

input[type="search"] {
  border: 1px solid #bbbbbb;
  border-radius: 41px;
  padding: 8px 16px;
  margin-right: 5px;
  margin-bottom: 8px;
  font-size: 12px;
  outline: none;
}

select {
  border: 1px solid #bbbbbb;
  border-radius: 21px;
  padding: 8px 8px;
  margin-right: 5px;
  margin-bottom: 8px;
  font-size: 14px;
  outline: none;
  width: 190px;
}

input[type="checkbox"] {
  background-color: white;
  line-height: 16px;
  border-radius: 3px;
  font-size: 16px;
  font-family: "agGridBalham";
  color: #7f8c8d;

  &:checked {
    color: #0091ea;
  }
}

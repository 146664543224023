.container {
  max-width: 100%;
  width: calc(100% - 150px);
  margin: auto;
}

//MDBootstrapModule related. This will be obsolete soon.
.orsted-blue {
  background-color: $blue !important;
}

.sandlight25 {
  background-color: $sandlight25 !important;
}

.button-primary {
  -webkit-border-radius: 10em;
  border-radius: 10em;
  background-color: $blue;
}

thead tr {
  text-transform: uppercase;
}

// Override md bootstrap variables
$table-accent-bg: $sandlight25;
$table-hover-bg: $sandlight75;
$table-border-color: transparent;

.card {
  box-shadow: none;
  border: 0;
  font-weight: 400;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
  background-color: $sandlight25;
}

.card-text {
  color: #747373;
  font-size: 0.9rem;
  font-weight: 400;
  padding-top: 10px;
}

@media (min-width: 0) and (max-width: 492px) {
  .container {
    width: 100%;
  }
}

.fw-bold {
  font-family: "OrstedSans-Bold";
}

/* Bootstrap 5 removed this utility class. */
.form-group {
  margin-bottom: 1rem;
}

@import "app/colors";
@import "app/input";
@import "app/material";
@import "app/table";
@import "app/bootstrap";
@import "app/features";

@font-face {
  font-family: "OrstedSans-Regular";
  src: url("./assets/fonts/OrstedSans-Regular.woff") format("woff"), url("./assets/fonts/OrstedSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OrstedSans-Italic";
  src: url("./assets/fonts/OrstedSans-Italic.woff") format("woff"), url("./assets/fonts/OrstedSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "OrstedSans-Bold";
  src: url("./assets/fonts/OrstedSans-Bold.woff") format("woff"), url("./assets/fonts/OrstedSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "OrstedSans-Light";
  src: url("./assets/fonts/OrstedSans-Light.woff") format("woff"), url("./assets/fonts/OrstedSans-Light.ttf") format("truetype");
}

body {
  font-family: "OrstedSans-Regular", "Roboto", sans-serif;
  color: $text;
  margin: 0;
  padding: 0;
}

input[type="checkbox"] {
  appearance: auto;
}

.text-primary {
  color: $blue !important;
}

.bg-project {
  background: $project;
}

.bg-package {
  background: $package;
}

.bg-subpackage {
  background: $subpackage;
}

.bg-contractor {
  background: $contractor;
}

.bg-location {
  background: $location;
}

.bg-component {
  background: $component;
}

.bg-checklist {
  background: $checklist;
}

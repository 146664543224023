$blue: #4099da;
$sun: #fdd779;
$aubergine: #644c76;
$coral: #e85757;
$aqua: #8ecdc8;
$text: #3b4956;

$blue25: #6fb2e3;
$sanddark: #b7ada5;

$sanddark75: #c9c1bb;
$sanddark50: #dbd6d2;
$sanddark25: #edeae8;

$sandlight: #d8d1ca;
$sandlight75: #e2dcd7;
$sandlight50: #ebe8e4;
$sandlight25: #f5f4f2;

$text25: #99a4ae;
$white: #ffffff;

$info: #fdd779;
$error: #e85757;
$success: #009775;

$project: #3179af;
$package: #7e6490;
$subpackage: #4e7471;
$contractor: #b25606;
$location: #c93b40;
$component: #5f7386;
$checklist: #5f7386;

//Shared
.hidden {
  display: none;
}

//Navigation
.nav-home {
  background: $blue25;
  left: 0;
  top: 0%;
}

.nav-logo-title {
  font-weight: 600;
  font-size: 24px;
  margin-left: 5px;
  margin-right: 5px;
  color: $white;
  vertical-align: middle;
  padding-left: 10px;
}

mat-icon[nav-icon] {
  margin: 20px 20px;
  cursor: pointer;
}

.nav-logo {
  margin-left: 20px;
  margin-right: 20px;
}

.nav-profile {
  height: 33px;
  position: absolute;
  right: 80px;
}

.nav-profile {
  color: white;
  font-size: 16px;
  .logout {
    font-weight: bold;
  }
}

mat-icon[nav-profile-icon] {
  margin: 5px;
}

.nav-settings {
  background: $blue25;
  position: absolute;
  right: 0;
}

ul.nav-links {
  display: inline-flex;
  list-style: none;
  align-content: center;
  margin: 0px 20px;
  padding-inline-start: 0;
  margin-inline-start: 0;
  li a {
    color: white;
    margin-left: 40px;
    font-size: 19px;
    line-height: 23px;
    font-family: "OrstedSans-Regular";
  }

  li a.inactive {
    color: $sanddark50;
  }

  li a.active {
    padding-bottom: 16px;
    border-bottom: solid 5px white;
  }
}

//Checklist-Overview
title {
  display: block;
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  color: $blue;
  margin: 20px 0;
}

span.handover-link {
  color: #009775;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  display: inline;
  margin: 20px;
}

span.clickable-link-underline {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.actions {
  margin: 10px 0;
  display: flex;
  & button {
    height: 36px;
    &.inactive {
      background-color: $sanddark;
      color: white;
      pointer-events: none;
    }
  }
  & input {
    width: 300px;
  }

  & label {
    padding-top: 6px;
    font-size: 14px;
    & mat-icon {
      color: black;
    }

    &.message {
      margin-left: 20px;
    }
  }
}

.spacer {
  display: flex;
  flex: 1 1 auto;
}

.loader {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #3b4956;
}

.emptyspacer {
  height: 50px;
  display: block;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

span.released {
  color: $success;
  font-family: OrstedSans-Bold, sans-serif;
}

span.draft {
  color: $info;
  font-family: OrstedSans-Bold, sans-serif;
}

span.revoked {
  color: $error;
  font-family: OrstedSans-Bold, sans-serif;
}

span.open {
  color: $info;
  font-family: OrstedSans-Bold, sans-serif;
}

span.completed {
  color: $success;
  font-family: OrstedSans-Bold, sans-serif;
}

span.deleted {
  color: $error;
  font-family: OrstedSans-Bold, sans-serif;
}
